import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useTable } from 'react-table'

class App extends React.Component {

  componentDidMount() {
    document.title = "Military Pay Dates";
  }

  render() {
    return (
      <div className="App">
        <h3>Military Pay Dates</h3>
        <p>Here are the pay dates for when you get your paycheck directly into a bank and when you accelerate it through <a href="https://www.earnin.com/" target="_blank">Earnin Express</a></p>
       <table>
           <tr>
            <th>Month</th>
            <th>Pay Type</th>
            <th>Description</th>
            <th>Regular Pay Date</th>
            <th>Earnin Express Pay Date</th>
            <th>Average Amount</th>
            <th>Median Amount</th>
           </tr>
           <tr>
             <td>April 2021</td>
             <td>Marine Corps</td>
             <td>MCTF TREAS 310</td>
             <td>4/1/2021</td>
             <td>3/27/2021</td>
             <td>$1379.36</td>
             <td>$1128.49</td>
           </tr>
           <tr>
             <td>April 2021</td>
             <td>DFAS - Active Army</td>
             <td>DFAS-IN IND, IN:DO SYMBOL 5570-AA</td>
             <td>4/1/2021</td>
             <td>3/26/2021</td>
             <td>$1425.01</td>
             <td>$1220.52</td>
           </tr>
           <tr>
             <td>April 2021</td>
             <td>DFAS - Reserve Corps</td>
             <td>DFAS-IN IND, IN:DO SYMBOL 5570-RC</td>
             <td>4/1/2021</td>
             <td>3/26/2021</td>
             <td>$2403.19</td>
             <td>$2279.28</td>
           </tr>
           <tr>
             <td>April 2021</td>
             <td>DFAS - Joint Uniform Military Pay System</td>
             <td>DFAS-IN IND, IN:DO SYMBOL 5570-JUMPS</td>
             <td>4/1/2021</td>
             <td>3/26/2021</td>
             <td>$1993.44</td>
             <td>$1886.30</td>
           </tr>
           <tr>
             <td>April 2021</td>
             <td>DFAS</td>
             <td>DFAS-CLEVELANDZPV381600NET PAY</td>
             <td>3/19/2021</td>
             <td>3/17/2021</td>
             <td>$1192.35</td>
             <td>$1226.64</td>
           </tr>
       </table>
      </div>
    );
  }
}

export default App;
